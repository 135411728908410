@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --orange: #dc3545;
  --blue: #035e71;
  --white: #ffffff;
  --black: #000000;
  --gray: #5e788f;
}

.App{
  width: 100%;
  height: 100%;
}

header{
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background-color: transparent;
  align-items: center;
}

.header-container{
  display: flex;
  max-width: 1400px;
  width: 100%;
}

.logo{
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  text-decoration: none;
  padding-top: 20px;
  padding-left: 20px;
}

.logo h3{
  padding-left: 1em;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.2em;
  margin: 0;
  line-height: 20px;
}

.logo b {
  color: var(--white);
  font-size: 1.3em;
}

.logo img{
  border-radius: 20px;
  max-width: 30%;
}

.content-area{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}

.background-video{
  position: absolute;
  z-index: -555;
}

.mah-buttons{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;
}

.mah-button{
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.2em;
  cursor: pointer;
  padding: 0.5em;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
  border-radius: 10px;
  border: 2px solid #c0c0c081;
  flex-grow: 1;
  background-color: transparent;
  color: var(--white);
  justify-content: center;
  min-width: 200px;
}

.mah-button:hover{
  background-color: var(--orange);
  color: var(--white);
  border: 2px solid var(--orange);
}

.mah-button img {
  max-height: 35px;
  max-width: 35px;
  padding-right: 8px;
}

footer{
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: var(--white);
  height: 70px;
}

.footer-container{
  width: 1400px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.footer-container h2{
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.2em;
}

#baskan-foto{
  width: 400px;
}

.socials{
  margin-bottom: 1em;
}

.socials a{
  margin-right: 1em;
  width: 2em;
}

.project-container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
}

.project-item::after{
  position: absolute;
}

.project-item{
  transition: all 1s;
  cursor: pointer;
  padding: 20px;
  border-radius: 20px;
  border: 2px solid #c0c0c081;
  height: fit-content;
  width: 29%;
  margin-bottom: 10px;
}

.project-item img{
  width: 90%;
}

#main-title{
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: var(--orange);
  font-size: 40px;
  letter-spacing: 3px;
}

.project-details{
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.project-title{
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: 22px;
  line-height: 30px;
  padding: 0;
  margin-top: 0;
  max-width: 85%;
}

.project-icon{
  width: 50px;
  line-height: 30px;
  min-width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#project-image{
  display: block;
  border-radius: 15px;
  object-fit: cover;
  height: 270px;
  width: 100%;
}

.project-description{
  display: flex;
}

.project-description p{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: justify;
}

.share-container{
  display: flex;
  flex-direction: row;
  background-color: #f1f1f1;
  justify-content: space-around;
  border-radius: 10px;
  margin-top: 30px;
}

.share-container a{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.share-container a:hover path{
  fill: #fff;
  width: 100%;
}

.share-container a:hover{
  background-color: var(--orange);
}

.mah-header{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  min-height: 410px;
  position: relative;
  justify-content: center;
}

.header-image{
  position: absolute;
  bottom: 0;
  z-index: -2;
  object-fit: cover;
  height: 100%;
}

.header-image img{
  z-index: -2;
  object-fit: cover;
  max-width: 99.1vw;
  filter: blur(0px);
}

.mah-image{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: -1;
  margin-bottom: 350px;
}

.mah-title{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  line-height: 70px;
}

.mah-title h1{
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: 80px;
  margin: 0;
  padding: 0;
}

.mah-title h3{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 30px;
  margin: 0;
  padding: 0;
  color: var(--white);
  letter-spacing: 2px;
  line-height: 70px;
}

.header-buttons{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
}

.header-buttons button{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #ffffff91;
  background-color: transparent;
  color: var(--white);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-buttons button:hover{
  background-color: var(--orange);
  color: var(--white);
  border: 2px solid var(--orange);
}

.filter::after {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  background: #a12831;
  mix-blend-mode: multiply;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.filter-header::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #a12831;
  mix-blend-mode: multiply;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#arrow-icon{ 
  padding-right: 5px;
  padding-left: 5px;
}

#mahalle-icon{
  padding-right: 5px;
  padding-left: 5px;
}

@media (max-width: 1420px) {
  .project-item{
    width: 45%;
  }
}

@media only screen and (max-width: 1200px) {
  .project-item{
    width: 90%;
  }

  .project-container {
    justify-content: center;
  }

  .filter::after {
    position: fixed;
  }

  .mah-title h1{
    font-size: 45px;
  }

  .mah-title h3{
    font-size: 20px;
  }

  .header-buttons button{
    font-size: 14px;
  }
}

@media (max-height: 625px) and (min-width: 1100px) {
  footer{
    position: relative;
  }
  
  .filter::after {
    position: fixed;
  }
}

@media (max-width:660px) {
  .socials a{
    margin-right: 1em;
    color: white;
    width: 1em;
  }
  
  .filter::after {
    position: fixed;
  }

  #baskan-foto{
    width: 300px;
  }
}

@media (max-width:450px) {
  #baskan-foto{
    width: 180px;
  }
}